<template>
	<DialogV2 ref="dialog"
		:title="$t('text.translationWizardTitle')"
		:confirmLabel="$t('text.confirmSelection')"
		:onConfirm="confirmTranslations"
	>
		<template #sidebar>
			<LanguageSidebar :checkIfHasMissingTranslations="checkIfHasMissingTranslations" />
		</template>

		<template #content>
			<div class="translation-wizard-inputs">
				<!-- All Languages -->
				<template v-if="activeTranslation === 'all'">
					<div v-for="(key, index) in Object.keys(fields)" :key="key" :class="index ? 'py-4' : 'pb-4'">
						<TranslateableField
							:typeName="typeName"
							:fieldName="key"
							:modelValue="getModelValue(model.fields, fields[key].id)"
							:fieldLocale="activeTranslation"
							:locales="displayedLocales"
							:ticketOnly="ticketOnly"
              v-on="subHandlers"
						/>
					</div>
				</template>
				<!-- Per Language -->
				<template v-else v-for="key in Object.keys(fields)">
					<TranslateableField
						:key="key"
						v-if="fields[key].section ? hasField(fields[key].section, key, model) : true"
						:typeName="typeName"
						:fieldName="key"
						:modelValue="getModelValue(model.fields, fields[key].id)"
						:fieldLocale="activeTranslation"
						:locales="displayedLocales"
						:ticketOnly="ticketOnly"
            v-on="subHandlers"
					/>
				</template>
      </div>
      <DialogV2
        ref="settingsDialog"
        :title="$t('text.translationSettings')"
        :onConfirm="confirmTranslationSettings"
        :disableConfirmButton="translationSettings === null"
      >
        <template #content>
          <TranslationSettings v-model="translationSettings" :serviceProviderId="model.fields?.serviceProvider?.de?.sys?.id" />
        </template>
      </DialogV2>
		</template>
	</DialogV2>
</template>

<script lang="ts">
import Common from '@/mixins/Common.vue'
import LanguagesNavigation from '@/mixins/LanguagesNavigation.vue'
import DialogV2 from '@/components/common/DialogV2.vue'
import LanguageSidebar from '@/components/common/LanguageSidebar.vue'
import TranslateableField from './fields/TranslateableField.vue'
import TranslationSettings from "@/components/common/TranslationSettings.vue";
import { translationActions } from '@/constants';

export default {
	name: 'TranslationWizardDialog',
	props: {
		modelValue: Object,
		typeName: String,
	},
	mixins: [ Common, LanguagesNavigation ],
	components: { TranslationSettings, DialogV2, LanguageSidebar, TranslateableField },
	emits: [ 'update:modelValue' ],
	data() {
		return {
			currentStep: 1,
			model: null,
      translationSettings: null
		}
	},
	watch: {
		modelValue(value) {
			this.model = value
		},
	},
	computed: {
		activeTranslation() {
			return this.$store.state.activeTranslation
		},
		displayedLocales() {
			return this.languageNavigationItems.reduce((locales, { code }) => {
				if (code !== "all" && (this.activeTranslation === "all" || this.activeTranslation === code)) {
					locales.push(code)
				}

				return locales
			}, [])
		},
		fields() {
			const fields = this.$store.state.fieldModels.find(({ name }) => name === this.typeName)?.fields ?? {}

			return Object.fromEntries(Object.entries(fields).filter(([_, field]) => field.localized))
		},
		ticketOnly() {
			return this.modelValue.fields?.contentMode?.de === 'ticket'
		},
    subHandlers() {
      return translationActions.reduce((handlers, value) => {
        switch (value.id) {
          case 'translate':
            handlers[value.id] = this.translate
            break
          case 'settings':
            handlers[value.id] = () => this.$refs.settingsDialog.open()
            break
          default:
            handlers[value.id] = () => console.log('TranslationWizardDialog ' + value.id)
        }
        return handlers;
      }, {});
    },
	},
	methods: {
		getModelValue(data, key) {
			if (!data) return undefined
			if (key.indexOf('.') === -1) return data[key]

			let value = data

			key.split('.').forEach(keyPart => {
				value = value[keyPart]
			})

			return value
		},
		open() {
      if (this.$refs?.dialog) return this.$refs.dialog.open()
		},
		close() {
			return this.$refs.dialog.close()
		},
		checkIfHasMissingTranslations(locale) {
			return Object.keys(this.fields)?.some(key => {
				if (this.fields[key].section && !this.hasField(this.fields[key].section, key, this.model)) return false

				return !this.getModelValue(this.model.fields, this.fields[key].id)?.[locale]
			})
		},
		confirmTranslations() {
			this.$emit('update:modelValue', this.model)
		},
    async translate(args) {
      const localeText = args.text[args.locale]
      try {
        const res = await this.$httpGet('/aiTranslation', { locale: 'de', text: localeText })
        if (res.translation) {
          this.model.fields[args.key].de = res.translation
        }
      } catch (error) {
        console.log(error)
      }
    },
    async confirmTranslationSettings() {
      const serviceProviderId = this.model.fields?.serviceProvider?.de?.sys?.id
      if (this.translationSettings === null || !serviceProviderId) {
        return
      }
      try {
        const res = await this.$httpPost('/aiSettings', { translationSettings: this.translationSettings, serviceProviderId })
      } catch (error) {
        console.log(error)
      }
    }
	},
	mounted() {
		this.model = this.modelValue
	},
}
</script>

<style lang="scss">
.translation-wizard-inputs {
	display: flex;
	position: relative;
	flex-direction: column;
	flex-grow: 1;

	&.all {
		.input-field>.input-field-content {
			padding: 16px 0;
		}
	}
}
</style>