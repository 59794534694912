<template>
	<Application>
		<template #navbar></template>
		<div class="Playground">
			<!--
				TODO: we arent actually making sure exceptions are last in the list
					we manually have to make di a 2-phase-calculation
			-->
			Api2Fastify: <Api2FastifyTest />
			<!--
			PriceMatrixCalendar: <PriceMatrixCalendar style="background: white;" :basePrices="basePrices" :ticketTypes="ticketTypes" :ticketCategories="ticketCategories" v-model="profiles" />
			PriceMatrixCalendar exceptions: <PriceMatrixCalendar mode="exception" style="background: white;" :basePrices="basePrices" :ticketTypes="ticketTypes" :ticketCategories="ticketCategories" v-model="profiles" />
			<MultiDatePicker v-model="multiDatePickerDates" :profile="profiles[0]" :profiles="profiles" />
			PriceMatrix: <PriceMatrix v-model="prices" :basePrices="basePrices" :ticketTypes="ticketTypes" :ticketCategories="ticketCategories" />
			PriceMatrixMerged: <PriceMatrixMerged v-model="pricesMerged" :basePrices="basePrices" :ticketTypes="ticketTypes" :ticketCategories="ticketCategories" :profiles="profiles" />
			DraggableDisclosureList: <DraggableDisclosureList v-model="profiles" startExpanded>
				<template #header>
					Season Name (required)
				</template>
				<template #item-header="{ item }">
					<ColorPicker v-model="item.color" />
					{{ item.name }}
				</template>
				<template #item-body="{ item }">
					<PriceMatrix v-model="prices" :ticketTypes="ticketTypes" :ticketCategories="ticketCategories" />
				</template>
			</DraggableDisclosureList>
			ColorPicker: <ColorPicker v-model="color" />
			AvailabilityColorCalendar: <AvailabilityColorCalendar style="background: white;" />
			Api2NestTest: <Api2NestTest />
			Comments: <Comments objectId="MA-2ff78ed1-7a50-42f1-8c3d-e61fe9bf7f64" style="padding: 20px; background: white;" />
			<ContingentSlot :modelValue="{ time: 'All Day', total: 4, free: 4 }" :day="{ date: '2024-10-10' }" :dates="{ today: '2024-10-10' }" />
			IsoDatePicker ({{ IsoDatePickerModel }}): <IsoDatePicker v-model="IsoDatePickerModel" />
			IsoDatePicker ({{ IsoDatePickerModel }}): <IsoDatePicker v-model="IsoDatePickerModel" />
			DatePickerCalendar ({{ DatePickerCalendarModel }}): <DatePickerCalendar v-model="DatePickerCalendarModel" />
			SeatPlanEditor: <SeatPlanEditor />
			TestStepperDialog: <TestStepperDialog />
			TestRmi: <TestRmi />
			TrackingView <TrackingView entryType="product" entryId="MA-2ff78ed1-7a50-42f1-8c3d-e61fe9bf7f64" />
			PeakServiceProviders: <PeakServiceProviders />
			ImageEnhancerPlayground: <ImageEnhancerPlayground />
			BusinessHoursProfileCalendar: <BusinessHoursProfileCalendar style="background: white;" />
			-->
		</div>
	</Application>
</template>

<script lang="ts">
import TestRmi from './playground/TestRmi.vue'
import ImageEnhancerPlayground from './applications/mediaGallery/ImageEnhancerPlayground.vue'
import PeakServiceProviders from './applications/packageDesigner/PeakServiceProviders.vue'
import Application from './applications/Application.vue'
import BusinessHoursProfileCalendar from '../components/calendar/BusinessHoursProfileCalendar.vue'
import PriceMatrixCalendar from '../components/calendar/PriceMatrixCalendar.vue'
import TrackingView from '../components/common/TrackingView.vue'
import TestStepperDialog from './playground/TestStepperDialog.vue'
import SeatPlanEditor from './playground/SeatPlanEditor.vue'
import Comments from '../components/comments/Comments.vue'
import DatePickerCalendar from './applications/packageDesigner/DatePickerCalendar.vue'
import IsoDatePicker from '../components/businesshours/IsoDatePicker2.vue'
import Api2NestTest from './playground/Api2NestTest.vue'
import ContingentSlot from '../components/serviceDesigner/contingentMonitor/ContingentSlot.vue'
import Api2FastifyTest from './playground/Api2FastifyTest.vue'
import AvailabilityColorCalendar from '../components/calendar/AvailabilityColorCalendar.vue'
import ColorPicker from '../components/common/ColorPicker.vue'
import PriceMatrix from '../components/calendar/PriceMatrix.vue'
import PriceMatrixMerged from '../components/calendar/PriceMatrixMerged.vue'
import DraggableDisclosureList from '../components/calendar/DraggableDisclosureList.vue'
import MultiDatePicker from '../components/calendar/MultiDatePicker.vue'

export default {
	components: { TestRmi, PeakServiceProviders, ImageEnhancerPlayground, Application, BusinessHoursProfileCalendar, PriceMatrixCalendar, TrackingView, TestStepperDialog, SeatPlanEditor, Comments, DatePickerCalendar, IsoDatePicker, Api2NestTest, ContingentSlot, Api2FastifyTest, AvailabilityColorCalendar, ColorPicker, PriceMatrix, PriceMatrixMerged, DraggableDisclosureList, MultiDatePicker },
	data: () => ({
		DatePickerCalendarModel: '2024-10-10',
		IsoDatePickerModel: '2024-10-09T22:00:00.000Z',
		color: '#ff0000',
		basePrices: [ { ticketCategory: 'adult', ticketType: 'd1', price: 15 }, { ticketCategory: 'adult', ticketType: 'd2', price: 25 }, { ticketCategory: 'child', ticketType: 'd2', price: 10 } ],
		prices: [ { ticketCategory: 'adult', ticketType: 'd1', price: 10 }, { ticketCategory: 'adult', ticketType: 'd2', price: 20 } ],
		// TODO: test that merging ignores non-existing prices
		pricesMerged: [ { ticketCategory: 'adult', ticketType: 'd1', sourceId: 1, price: 10 }, { ticketCategory: 'child', ticketType: 'd2', sourceId: 2, price: 20 } ],
		ticketCategories: [
			{ sys: { id: 'adult' }, fields: { title: { de: 'Adult' } } },
			{ sys: { id: 'child' }, fields: { title: { de: 'Child' } } },
			{ sys: { id: 'senior' }, fields: { title: { de: 'Senior (> 65)' } } },
		],
		ticketTypes: [
			{ sys: { id: 'd1' }, fields: { title: { de: '1 Day' } } },
			{ sys: { id: 'd2' }, fields: { title: { de: '2 Day' } } },
		],
		profiles: [
			// prices: { adult_1: 0, child_1: 0, senior_1: 0, adult_2: 0, child_2: 0, senior_2: 0 }
			//{ id: 0, color: null, name: 'default', weekdays: [], prices: {}, isRemove: true },
			{ id: 1, color: '#aae4fa', name: 'summer', weekdays: [], prices: [ { ticketCategory: 'adult', ticketType: 'd1', price: 10 } ], start: '2024-10-01', end: '2024-10-31' },
			{ id: 2, color: '#cbedb6', name: 'winter', weekdays: [], prices: [], start: null, end: null },
			{ id: 3, color: '#ffe6aa', name: 'holidays', weekdays: [], prices: [ { ticketCategory: 'child', ticketType: 'd2', price: 5 } ], start: null, end: null, dates: [ '2024-10-10', '2024-10-11' ] },
		],
		multiDatePickerDates: [],
	}),
}
</script>

<style scoped>
.Playground { padding: 60px 0px; }
.Playground > * { border: 1px solid silver; margin-bottom: 20px; }
</style>