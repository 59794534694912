<template>
	<div class="AddedValueType">
		<v-select v-model="model" @update:modelValue="$emit('update:modelValue', id, model)" :items="types" :item-props="typeProps" :item-value="type => type.id" hide-details
			variant="outlined" density="compact" bg-color="#FFF" @click.stop
			:disabled="isRailawayPackage"
		>
			<template #chip="{ item }">{{ item.title }} <span v-if="avType">- {{ $t('text.avType_' + avType) }}</span></template>
			<template #item="{ item, props }">
				<v-list-item v-bind="props"
					:title="$t('text.' + item.raw.id)"
					:subtitle="$t('text.' + item.raw.id + '-subtitle')"
					style="cursor: pointer;"
					:disabled="item.raw.id == AV_TYPES.MISCONFIGURED"
				>
					<template #prepend>
						<v-icon v-if="model == item.raw.id" color="primary">mdi-radiobox-marked</v-icon>
						<v-icon v-else>mdi-radiobox-blank</v-icon>
					</template>
				</v-list-item>
				<!-- TODO: remove once we are sure - i dont know yet if this might be better
				<div class="item">
					<v-icon v-if="model == item.raw.id">mdi-radiobox-marked</v-icon>
					<v-icon v-else>mdi-radiobox-blank</v-icon>
					<div>
						<div>{{ $t('text.' + item.raw.id) }}</div>
						<div class="subtitle">{{ $t('text.' + item.raw.id + '-subtitle') }}</div>
					</div>
				</div>
				-->
			</template>
			<template #append>
				<v-tooltip location="bottom">
					<template #activator="{ props }">
						<v-icon v-bind="props">mdi-information</v-icon>
					</template>
					<div class="tooltip">
						<p class="tooltip-title">{{ $t('text.addedOfferTooltipTitle') }}</p>
						<p>{{ $t('text.addedOfferTooltipText') }}</p>
					</div>
				</v-tooltip>
			</template>
<!--			<template #append-item>
				<v-list-item style="cursor: pointer;" prepend-icon="mdi-cog" @click="$emit('customizeOptions')">
					{{ $t('text.customizeOptions') }}
				</v-list-item>
			</template>-->
		</v-select>
	</div>
</template>

<script>
import { AV_TYPES } from '@/constants'

export default {
	name: 'AddedValueType',
	emits: ['update:modelValue'],
	props: {
		modelValue: String,
		isRailawayPackage: Boolean,
		skus: Array,
		id: String,
	},
	data: () => ({
		model: AV_TYPES.INCLUSIVE
	}),
	watch: {
		model(n) {
			this.$emit('update:modelValue', n)
		},
		modelValue(n) {
			this.model = n
		},
	},
	computed: {
    types() {
      return this.isRailawayPackage ?
          [{id: AV_TYPES.OV}] :
          [
            {id: AV_TYPES.INCLUSIVE,},
            {id: AV_TYPES.OPTIONAL},
            {id: AV_TYPES.FIXED},
            {id: AV_TYPES.MISCONFIGURED},
          ]
    },
		avType() {
			const sku = this.skus?.[0]
			if (!sku?.startsWith) return
			// ATT: copied from PDS.upsertPermission
			let avType = null
			if (sku === 'TRANSPORTATION.TICKET' || sku.startsWith('BERGBAHN')) avType = 'bergbahn'
			else if (sku.startsWith('SKIPASS')) avType = 'skipass'
			else if (sku.startsWith('EVENT')) avType = 'event'
			//else throw `Could not determine added value type! ${sku}`
			return avType
		},
	},
	methods: {
		typeProps(type) {
			return {
				// TODO: the keys are too general - we need to prefix them with something
				title: this.$t('text.' + type.id),
				subtitle: this.$t('text.' + type.id + '-subtitle'),
			}
		},
		initDefaultAvType() {
			if (this.modelValue) {
				this.model = this.modelValue
				return
			}

			if (this.isRailawayPackage) this.model = AV_TYPES.OV
		},
	},
	setup() {
		return { AV_TYPES }
	},
	mounted() {
		this.initDefaultAvType()
	},
}
</script>

<style scoped lang="scss">
.item {
	display: flex;
	gap: 10px;
	align-items: center;
	padding: 5px 10px;
	border-bottom: 1px solid #ddd;
}

.subtitle {
	font-size: smaller;
	color: gray;
}

.tooltip {
	max-width: 320px;

	p {
		color: #000;
		font-size: 15px;
	}

	.tooltip-title {
		font-weight: bold;
	}
}
</style>