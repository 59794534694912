// TODO: find a good serialization library handling all this: classes, dedup, circular, dates, ..

// TODO: handle dates - how can this be done? a a date gets passed as a string and we dont have a good way to distinguish it from a string
//       we may need to preprocess the object tree and replace the dates first..
// TODO: dedup large primitives? the benefit of this is probably low

// this is a simple serialization library that handles classes and circular references
// the serialized format is JSON, but the JSON can not just be used as is.
// Examples:
// simple cases are just serialized as is:
// { a: 1, b: 2 } => { a: 1, b: 2 }
// every object is serialized with an _i property, which is a unique identifier
// { a: { x: 1 } } => { a: { x: 1, _i: '_i:1' } }
// duplicate objects are serialized as references
// { a: { x: 1 }, b: { x: 1 (same obj as on a) } } => { a: { x: 1, _i: '_i:1' }, b: '_i:1' }
// this also solves circular references
// { a: 1, b: { a: 1, b: ... (same obj as root) } } => { a: 1, b: '_i:1', _i: '_i:1' }

import { Entry2 } from './entry-util'
import { dtoClasses } from './model-util'

export function objectToJson(object: any): string {
	const objectToInfo = new Map<{}, { _i: string, _c: string }>()
	let objectCount = 1
	return JSON.stringify(object, (key, value) => {
		if (key.startsWith('_') && key != '_i' && key != '_c') return undefined

		// remove any duplicates (and with that, cycles)
		if (typeof value === 'object' && value !== null) {
			// TODO: we could also deduplicate arrays, to have full client-server transparency
			//       but we cant add the info to arrays, so we would need something new else..
			if (Array.isArray(value)) {
				return value
			}
			const info = objectToInfo.get(value)
			if (!info) {
				const info = {
					_i: '_i:' + objectCount,
					_c: value.constructor?.name ?? 'UNEXPECTED',
				}
				if (info._c == 'Object') delete info._c
				objectToInfo.set(value, info)
				objectCount++
				value = { ...value, ...info }
			}
			else {
				return info._i
			}
		}

		//if (value instanceof Entry2) return value.toJSON()
		return value
	})
}

export function jsonToObject(text: string): any {
	// "_i:1" => { ... }
	const lookup = {}
	const r = JSON.parse(text, (key, value) => {
		if (typeof value == 'object' && value !== null) {
			// revive the class
			if (value._c) {
				const clas = dtoClasses[value._c]
				if (clas) {
					Object.setPrototypeOf(value, clas.prototype)
				}
				delete value._c
			}
			// build lookup table
			if (value._i) {
				lookup[value._i] = value
				delete value._i
			}
		}

		// use lookup table to replace references
		if (typeof value == 'string' && value.startsWith('_i:')) {
			// in case we dont have the value yet, we defer to a post-processing step
			if (!lookup[value]) return value
			return lookup[value]
		}

		// we skip any underscore properties
		// this also handles the __proto__ vulnerability of JSON.parse
		if (key.startsWith('_') && key != '_i' && key != '_c') return undefined

		// TODO: special handling for links?
		//if (value?.sys?.type == 'Link') {

		// TODO: are Entries even a use case any more?
		const ct = value?.sys?.content_type?.sys?.id
		if (ct) {
			// TODO: properly revive the classes by NEWing instead?
			console.log('RMI: proxying Entry', value)
			return Entry2.proxy(value)
		}
		return value
	})

	// recursively walk r and replace the _i references with the actual objects
	const seen = new Set()
	function assignRefs(o) {
		if (typeof o != 'object') return
		seen.add(o)
		if (o && o._i) delete o._i
		for (const key in o) {
			const value = o[key]
			if (key == '_i') continue
			if (typeof value == 'string' && value.startsWith('_i:')) {
				const ref = lookup[value]
				if (ref) o[key] = ref
			}
			if (typeof value == 'object') {
				if (seen.has(value)) continue
				assignRefs(value)
			}
		}
	}
	assignRefs(r)

	return r
}