<template>
	<div>
		Fastify Test: {{ result }}<br />
		Fastify Test Restricted: {{ resultRestricted }}<br />
		Fastify Core Test: {{ resultCore }}<br />
	</div>
</template>

<script lang="ts">
//import Api2NestTestExecutive, { User } from '../../../../api2nest/src/rmi/Api2NestTestExecutive'
//import { User, Role } from '../../../../api2nest/src/entities'
import FastifyTestExecutive, { User } from '../../../../api2fastify/src/modules/test/FastifyTestExecutive'
import CoreExecutive from '../../../../api2fastify/src/modules/core/CoreExecutive'
import { metadata } from '../../../../api2fastify/src/util/model-util'

export default {
	data: () => ({
		result: null,
		resultRestricted: null,
		resultCore: null,
	}),
	async mounted() {
		console.log('metadata', metadata)
		new User('aaa')
		/*
		const u = new User('name')
		console.log('u', u)
		const x = new Api2NestTestExecutive({ clientId: 'C1' }, this)
		this.result = await x.test('hello')

		const cc = new FastifyTestExecutive(this)
		this.result = await cc.test('3')
		this.resultRestricted = await cc.testRestricted('9c7f0079-0e8b-488f-96ab-31f4540bfb8b')

		const ex = new CoreExecutive(this)
		const user = await ex.getUser('9c7f0079-0e8b-488f-96ab-31f4540bfb8b')
		this.resultCore = user
		*/
	},
}
</script>