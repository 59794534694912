<template>
  <FieldSet>
    <p class="subTitle" v-html="$t('text.translationConfig')"/>
    <p class="helpText" v-html="$t('text.translationConfigHelp')"/>
    <br/>
    <template v-if="model?.writingStyles">
      <v-label>{{$t('text.translationWritingStyle')}}</v-label>
      <p class="helpText" v-html="$t('text.translationWritingStyleHelp')"/>
      <div class="chip-field">
        <v-chip v-for="item in writingStyles" :key="`chip-${item}`" :class="{ active: model.writingStyles.includes(item) }" :color="model.writingStyles.includes(item) ? 'primary' : ''" label @click="chipClicked('writingStyles', item)">
          <v-icon class="chip__icon">${{itemIconSrc[item]}}</v-icon>
          {{ $t(`text.writingStyle${item[0].toUpperCase() + item.slice(1)}`) }}
        </v-chip>
      </div>
      <br/>
    </template>
    <template v-if="model?.tones">
      <v-label>{{$t('text.translationTone')}}</v-label>
      <p class="helpText" v-html="$t('text.translationToneHelp')"/>
      <div class="chip-field">
        <v-chip v-for="item in tones" :key="`chip-${item}`" :class="{ active: model.tones.includes(item) }" :color="model.tones.includes(item) ? 'primary' : ''" label @click="chipClicked('tones', item)">
          <v-icon class="chip__icon">${{itemIconSrc[item]}}</v-icon>
          {{ $t(`text.tone${item[0].toUpperCase() + item.slice(1)}`) }}
        </v-chip>
      </div>
      <br/>
    </template>
    <template v-if="model?.customization">
      <v-label>{{$t('text.translationCustomization')}}</v-label>
      <p class="helpText" v-html="$t('text.translationCustomizationHelp')"/>
      <Field v-model="model.customization" typeName="Freddy" fieldName="comment" :showTitle="false" ></Field>
    </template>
  </FieldSet>
</template>

<script lang="ts">
import Field from "@/components/fields/Field.vue";
import FieldSet from "@/views/applications/packageDesigner/FieldSet.vue";
import {FreddyTones, FreddyWritingStyles} from "@/constants";

export default {
  name: 'TranslationSettings',
  emits: ['update:modelValue'],
  components: {FieldSet, Field},
  props: {
    serviceProviderId: String
  },
  data() {
    return {
      modelValue: Object,
      model: null,
      writingStyles: Object.values(FreddyWritingStyles),
      selectedWritingStyles: this.model?.writingStyles || [],
      tones: Object.values(FreddyTones),
      selectedTones: this.model?.tones || []
    }
  },
  computed: {
    itemIconSrc() {
      return {
        'simple': 'iconMop',
        'business': 'iconWork',
        'academic': 'iconSchool',
        'casual': 'iconWavingHand',
        'enthusiastic': 'iconPersonCelebrate',
        'friendly': 'iconSentimentSatisfied',
        'confident': 'iconThumbUp',
        'diplomatic': 'iconHandshake',
      }
    }
  },
  methods: {
    chipClicked(type, item) {
      if (this.model[type].includes(item)) {
        this.model[type].splice(this.model[type].indexOf(item), 1)
      } else {
        this.model[type].push(item)
      }
    }
  },
  async mounted() {
    const res = await this.$httpGet(`/aiSettings/${this.serviceProviderId}` )
    if (res.translationSettings !== null) {
      this.model = res.translationSettings
      this.$emit('update:modelValue', this.model)
    }
  }
}

</script>
<style lang="scss">
.chip-field {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  margin-bottom: 10px;
  margin-top: 10px;
  .v-chip.active path {
    fill: #004660;
  }
  .chip__icon {
    margin-right: 8px;
  }
}
</style>