<template>
	<div class="PriceMatrixCalendarOverlaps"
		v-if="overlaps.length > 0"
	>
		<h1><v-icon>mdi-information</v-icon> {{ $t('text.overlap') }}</h1>
		<p v-if="mode == 'timespan'" v-html="$t('text.timespansOverlap_info')" />
		<p v-if="mode == 'exception'" v-html="$t('text.exceptionsOverlap_info')" />
		<div v-for="overlap of overlaps" :key="overlap.profile.id">
			<div v-for="o of overlap.overlaps" :key="o.profile.id" class="validationMessage">
				<I18nClozeText textKey="timespanOverlapsCloze" v-if="!o.dates">
					<template #a>
						<span class="profile"
							:style="{ '--col': overlap.profile.color }"
							@click="$emit('clickProfile', overlap.profile)"
						>{{ overlap.profile.name }}</span>
					</template>
					<template #b>
						<span class="profile"
							:style="{ '--col': o.profile.color }"
							@click="$emit('clickProfile', o.profile)"
						>{{ o.profile.name }}</span>
					</template>
					<template #from>
						{{ formatDate(o.span.start) }}
					</template>
					<template #until>
						{{ formatDate(o.span.end) }}
					</template>
					<template #duration>
						{{ o.span.duration }}
						{{ o.span.duration == 1 ? $t('text.day') : $t('text.days') }}
					</template>
				</I18nClozeText>
				<I18nClozeText textKey="exceptionOverlapsCloze" v-if="o.dates">
					<template #a>
						<span class="profile"
							:style="{ '--col': overlap.profile.color }"
							@click="$emit('clickProfile', overlap.profile)"
						>{{ overlap.profile.name }}</span>
					</template>
					<template #b>
						<span class="profile"
							:style="{ '--col': o.profile.color }"
							@click="$emit('clickProfile', o.profile)"
						>{{ o.profile.name }}</span>
					</template>
				</I18nClozeText>
				<ul v-if="o.dates" style="display: flex; flex-wrap: wrap; gap: 4px; font-size: inherit;">
					<li v-for="d of o.dates" :key="d"
						style="margin-left: 20px; width: 130px;"
						:style="{ '--col': overlap.profile.color }"
						@click="$emit('clickProfile', overlap.profile)"
					>
						{{ formatDate(d) }} ({{ weekdayOf(d) }})
					</li>
				</ul>
			</div>
		</div>
	</div>
</template>

<script lang="ts">
import moment from 'moment'
import { calcOverlaps } from '../../../../shared/seasonal-prices'
import I18nClozeText from '../common/I18nClozeText.vue'

export default {
	components: { I18nClozeText },
	props: {
		profiles: Array,
		mode: String,
	},
	computed: {
		filteredProfiles() {
			if (this.mode == 'timespan') return this.profiles.filter(p => !p.dates)
			if (this.mode == 'exception') return this.profiles.filter(p => p.dates)
			return []
		},
		overlaps() {
			return calcOverlaps(this.filteredProfiles)
		},
	},
	methods: {
		weekdayOf(date) {
			return this.$t('text.' + moment(date).format('dddd').toLowerCase()).substr(0, 2)
		},
	},
}
</script>

<style scoped>
.PriceMatrixCalendarOverlaps { margin: 12px 0; border: 1px solid #ddd; border-left: 6px solid #8f8f8f; border-radius: 4px; padding: 12px; }
h1 { color: #8f8f8f; margin: 0; margin-bottom: 4px; padding: 0; font-size: inherit; line-height: normal; display: flex; gap: 4px; align-items: center; }
p { font-size: inherit; margin-bottom: 12px !important; }
.validationMessage { margin: 5px 0; }
.profile { padding: 1px 7px; font-size: smaller; border-radius: 6px; margin: 0 2px; display: inline-block; background: var(--col); color: hsl(from var(--col) h 95% 25%); cursor: pointer; }
</style>