<template>
  <div :id="'translationActivator-' + uuid" class="translate-wrapper">
    <v-icon class="translate-icon">$iconAi</v-icon>
  </div>
  <v-menu :activator="'#translationActivator-' + uuid">
      <v-list>
        <v-list-item v-for="(option, index) in settings" :key="index" @click="$emit(option.id)">
          {{ $t(`text.${option.text}`) }}
        </v-list-item>
      </v-list>
  </v-menu>
</template>

<script lang="ts">
import uniqueId from 'lodash/uniqueId'
import { translationActions } from '@/constants';

export default {
  emits: ['settings', 'translate'],
  data() {
    return {
      settings: translationActions
    }
  },
  beforeCreate() {
    this.uuid = uniqueId()
  }
}
</script>
<style>
.translate-icon path {
  fill: var(--color-blue);
}
</style>/
